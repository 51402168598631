import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import parse from 'html-react-parser';
import React from 'react';
import * as s from './qrMenuMaker.module.css';
const QrMenuMaker = ({useH1}) => {
  return (
    <>
      <section className={`${s.qrMakerSection} d-flex position-relative`}>
        <div className="container d-flex">
          <div className="row align-items-center" style={{flexGrow: 1}}>
            <div className={`col-xl-7 col-lg-8 position-relative ${s.leftCol}`}>
              <div className={`d-flex align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-start ${s.topBlock}`}>
                {
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="logo"
                    src="./img/logo.png"
                    className={s.logoImage}
                  />
                }
                {!!useH1
                ? <h1 className={s.sectionTitle}>QR Menu Maker App <br className="d-none d-lg-block" /> Fast, Easy & Free!</h1>
                : <h2 className={s.sectionTitle}>QR Menu Maker App <br className="d-none d-lg-block" /> Fast, Easy & Free!</h2>
              }
              </div>
              <StaticImage
                placeholder="none"
                loading="eager"
                alt="logo"
                src="./img/qr.png"
                className={s.qrImage}
              />
              <div className={s.bottomBlock}>
                <h4 className={s.subtitle}>A Digital Menu For The Digital Age</h4>
                <a href='https://partners.qrmenu.orders.co' target='_blank' className={s.ctaBtn}>Try now on web</a>
                <div className={s.downloadWrapper}>
                  <h5 className={s.downloadText}>Download for mobile for free</h5>
                  <div className={`d-flex ${s.downloadBtnWrapper}`}>
                    <a href='https://play.google.com/store/apps/details?id=com.qrmenumaker' target='_blank' className={s.downloadBtn}>
                      <StaticImage
                        placeholder="none"
                        loading="eager"
                        alt="playMarket"
                        src="./img/playmarket.png"
                        className={s.downloadImage}
                      />
                    </a>
                    <a href='https://apps.apple.com/us/app/qr-menu-maker/id1643857790' target='_blank' className={s.downloadBtn}>
                      <StaticImage
                        placeholder="none"
                        loading="eager"
                        alt="playMarket"
                        src="./img/appstore.png"
                        className={s.downloadImage}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <StaticImage
              placeholder="none"
              loading="eager"
              alt="HeroBackground"
              src="./img/mobile.png"
              className={`d-lg-none ${s.backgroundImage}`}
            />
          </div>
        </div>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="HeroBackground"
          src="./img/desktop.png"
          className={`d-none d-lg-flex ${s.backgroundImage}`}
        />

      </section >
    </>
  )
}
export default QrMenuMaker;