import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import SlickArrowLeft from "./../SlickArrowLeft";
import SlickArrowRight from "./../slickArrowRight";
import * as s from "./testimonials-alt.module.css";
import { Link } from 'gatsby';

const TestimonialsAlt = ({ title, left_bg, items, right_bg, right_testimonial }) => {
  return (
    <>
      <section className={`${s.testimonials}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-5 d-flex flex-column justify-content-center">
              <h2 className={`${s.testimonialsTitle}`}>{title}</h2>
              <div className={`${s.testimonialsWrapper}`} style={{ backgroundImage: `url(${left_bg})` }}>
                {items.map((t, i) => {
                  return (
                    <div className={`${t.className ? t.className : ''} ${s.testimonialItem}`} key={`testimonial-${i}`}>
                      <div className={`${s.testimonialMedia} text-center`}>
                        {t.image}
                      </div>
                      <div className={`${s.testimonialsText}`}>{t.text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 col-xl-7">
              <div className={s.right} style={{ backgroundImage: `url(${right_bg})` }}>
                {!!right_testimonial.text &&
                  <div className={s.testimonialWrapper}>
                    <div className={s.imageWrapper}>
                      {right_testimonial.image}
                    </div>
                    <div className={s.text}>{right_testimonial.text}</div>
                    <h4>{right_testimonial.author}</h4>
                    <h6>{right_testimonial.position}</h6>
                  </div>
                }
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default TestimonialsAlt;
