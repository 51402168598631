import React from "react";
import * as s from "./image-text-slider.module.css";
import Slider from 'react-slick';

const ImageTextSlider = ({ items }) => {

  const [slider, setSlider] = React.useState(null);
  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    infinite: true,
    // centerMode: true,
    swipeToSlide: true,
    // autoplay: true,
    // autoplaySpeed: 3000,
  };

  const next = () => {
    if (null !== slider)
      slider.slickNext();
  }
  return (
    <section className={`${s.imageTextSliderBlock}`}>
      <Slider
        ref={c => { setSlider(c) }}
        {...sliderSettings}
      >
        {items?.length && items.map((item, i) => {
          return (
            <div key={`image-text-slider-item-${i}`}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    {item.image}
                  </div>
                  <div className="col-lg-6">
                    <span className={s.number}>{i + 1}</span>
                    <h2 className={s.title}>{item.title}</h2>
                    <div className={s.description}>{item.description}</div>
                    <div className={s.btnNext} onClick={next}></div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </section>
  );
};

export default ImageTextSlider;
