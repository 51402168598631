// extracted by mini-css-extract-plugin
export var backgroundImage = "qrMenuMaker-module--background-image--c9c17";
export var bottomBlock = "qrMenuMaker-module--bottom-block--754df";
export var ctaBtn = "qrMenuMaker-module--cta-btn--9544c";
export var downloadBtn = "qrMenuMaker-module--download-btn--7bdd2";
export var downloadBtnWrapper = "qrMenuMaker-module--download-btn-wrapper--d9983";
export var downloadText = "qrMenuMaker-module--download-text--5526c";
export var leftCol = "qrMenuMaker-module--left-col--7e188";
export var logoImage = "qrMenuMaker-module--logo-image--04ddf";
export var qrImage = "qrMenuMaker-module--qr-image--72d2a";
export var qrMakerSection = "qrMenuMaker-module--qr-maker-section--7e0d9";
export var sectionTitle = "qrMenuMaker-module--section-title--ad9df";
export var subtitle = "qrMenuMaker-module--subtitle--5f183";
export var topBlock = "qrMenuMaker-module--top-block--97a14";