// extracted by mini-css-extract-plugin
export var imageWrapper = "testimonials-alt-module--image-wrapper--0ce67";
export var right = "testimonials-alt-module--right--596f2";
export var star = "testimonials-alt-module--star--583a3";
export var stars = "testimonials-alt-module--stars--adf4f";
export var testimonialItem = "testimonials-alt-module--testimonial-item--7fcb8";
export var testimonialMedia = "testimonials-alt-module--testimonial-media--1972a";
export var testimonialWrapper = "testimonials-alt-module--testimonial-wrapper--5457b";
export var testimonials = "testimonials-alt-module--testimonials--602ae";
export var testimonialsText = "testimonials-alt-module--testimonials-text--38e55";
export var testimonialsTitle = "testimonials-alt-module--testimonials-title--5487f";
export var testimonialsWrapper = "testimonials-alt-module--testimonials-wrapper--c8afc";
export var text = "testimonials-alt-module--text--972c2";
export var teжт = "testimonials-alt-module--teжт--a8d4e";