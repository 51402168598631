import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BannerAlt from '../components/banner-alt/bannerAlt';
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import FeaturesAlt from '../components/featuresAlt/featuresAlt';
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageTextSlider from "../components/imageTextSlider/ImageTextSlider";
import News from "../components/news/news";
import Seo from "../components/seo";
import TestimonialsAlt from "../components/testimonialsAlt/testimonialsAlt";
import Title from "../components/title/title";
import Top from "../components/top/top";
import YoutubeVideo from '../components/youtubeVideo/youtubeVideo';
import gradientBg from "../images/QRMenu/gradient-pink-bg.png";
import iconAdv1 from "../images/QRMenu/icon-adv-1.png";
import iconAdv2 from "../images/QRMenu/icon-adv-2.png";
import iconAdv3 from "../images/QRMenu/icon-adv-3.png";

import testimonialsLeftBg from "../images/QRMenu/testimonials-left-bg.png";
import testimonialsRightBg from "../images/QRMenu/testimonials-right-bg.png";

import saladBg from "../images/QRMenu/salad.png";
import testimonialsState from "./../utils/testimonials";
import QrMenuMaker2 from './../components/qrMenuMaker/qrMenuMaker';

// markup
const QRMenuPage = ({ location, data }) => {
  const scrollToForm = "#";

  const topProps = {
    layout: 2,
    imageSize: "big",
    scrollToForm: scrollToForm,
    customButton: <>
      <h4 className="text-center">DOWNLOAD FOR FREE ON</h4>
      <div className="d-flex justify-content-center mt-5" style={{ gap: '28px' }}>
        <a className="bg-orange rounded-xl" href="https://play.google.com/store/apps/details?id=com.qrmenumaker" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="get-on-google-play"
            src="../images/QRMenu/get-on-google-play-white.png" />
        </a>
        <a className="bg-orange rounded-xl" href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="download-on-the-app-store"
            src="../images/QRMenu/download-on-the-app-store-white.png" />
        </a>
      </div>
    </>,
    title: (
      <div className="d-flex justify-content-center justify-content-xl-start" style={{ gap: '20px' }}>
        <StaticImage
          placeholder="none"
          loading="eager"
          width={107}
          height={107}
          style={{ marginLeft: '-127px' }}
          className="d-none d-md-block"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <StaticImage
          placeholder="none"
          loading="eager"
          width={52}
          height={52}
          className="d-md-none"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <div>
          QR Menu Maker App
          <br /> Fast, Easy & Free!
        </div>
      </div>
    ),
    subtitle: `A Digital Menu For The Digital Age`,
    hideLine: true,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="digital-menu"
        // src="../images/QRMenu/digital-menu.png"
        src="../images/QRMenu/qr-menu-maker.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "transparent",
      icon: iconAdv1,
      title: <b style={{ fontWeight: '700' }}>Faster Service</b>,
      description:
        `Reduce the time needed for staff to take orders by allowing customers to browse a QR menu in advance.
        Get orders into the kitchen up to 80% faster!`,
    },
    {
      bg: "transparent",
      icon: iconAdv2,
      title: <b style={{ fontWeight: '700' }}>Update Anywhere</b>,
      description:
        "Your QR Menu Maker goes where you go. Making on-the-fly updates effortless!",
    },
    {
      bg: "transparent",
      icon: iconAdv3,
      title: <b style={{ fontWeight: '700' }}>Accessibility</b>,
      description:
        "Anyone with a smartphone can view your QR menu whenever they want. Removing the need for a printed menu.",
    },
  ];

  const imageTextSliderItems = [
    {
      title: `Popularity of Self-Service`,
      description: `Self-checkout and Self Service industries have been growing in popularity since before the pandemic.
        Customers are favoring the time saved over waiting for service.`,
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="main-iphone-with-menu-with-tablet"
          src="../images/QRMenu/main-iphone-with-menu-with-tablet.png"
        />
      )
    },
    {
      title: `Expedite The Ordering Process`,
      description: `Scanning a QR code at a table and reviewing a menu right away can allow customers to decide prior
        to a server's arrival. Substantially reducing the average time needed to place an order.`,
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="scan-qr-menu"
          src="../images/QRMenu/scan-qr-menu.png"
        />
      )
    },
    {
      title: `Remove Expensive Printed Menus`,
      description: `Printing physical menus  takes time and cost money. With any future updates restarting the process.
        *QR Menu Maker* eliminates both and is free!`,
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="mobile-digital-menu"
          src="../images/QRMenu/mobile-digital-menu.png"
        />
      )
    },
  ];

  const features2 = [
    {
      bg: "#F9F9F9",
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="phone-in-hand"
          src="../images/QRMenu/phone-in-hand.png"
          className="mw-100 mt-4"
        />
      ),
      title: <>Take pictures of<br /> Dishes</>,
      description:
        `Make a nice image of every menu item. Your clients will 
        see photos when they browse the menu.`,
    },
    {
      bg: "#FFC397",
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="qr-menu-with-shadow"
          src="../images/QRMenu/qr-menu-with-shadow.png"
          className="mw-100 mb-5 mt-4"
        />
      ),
      title: <>Easy Menu Upload</>,
      description: <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="create-menu-button"
          src="../images/QRMenu/create-menu-button.png"
          className="mw-100 mt-4"
          width={250}
        />
      </>,
    },
    {
      bg: "#F9F9F9",
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="dish-with-vegetables"
          src="../images/QRMenu/dish-with-vegetables.png"
          className="mw-100 mb-5 mt-4"
        />
      ),
      title: <>Scan QR Code <br /> & Browse Menu</>,
      description:
        "Print and place QR code on each table. Customers scan the QR code and browse the menu.",
    },
  ];

  const banner1Props = {
    title: `Download and build your contactless menu now`,
    description: <>Get grow mobile app on Play store or App Store for free <br />
      Enjoy our best features</>,
    buttons: <div className="d-flex justify-content-start mt-5" style={{ gap: '8px' }}>
      <a style={{ backgroundColor: 'white', borderRadius: '20px' }} href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790" target="_blank" rel="nofollow">
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="app-store-black"
          width={175}
          height={57}
          src="../images/QRMenu/app-store-black.png" />
      </a>
      <a style={{ backgroundColor: 'white', borderRadius: '20px' }} href="https://play.google.com/store/apps/details?id=com.qrmenumaker" target="_blank" rel="nofollow">
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="google-play-black"
          width={190}
          height={57}
          src="../images/QRMenu/google-play-black.png" />
      </a>
    </div>,
    bgColor: "#000000",
    rightBgImage: saladBg,
    rightImage: <StaticImage
      placeholder="none"
      loading="eager"
      alt="man-on-moped"
      src="../images/QRMenu/man-on-moped.png" />
  }

  const testimonialsProps = {
    title: <>Give your customers<br /> the service they deserve</>,
    left_bg: testimonialsLeftBg,
    items: [
      {
        image: (
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="testimonial-1"
            src="../images/QRMenu/testimonial-1.png"
          />
        ),
        text: "Allow sharing your menu on social media",
      },
      {
        image: (
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="testimonial-2"
            src="../images/QRMenu/testimonial-2.png"
          />
        ),
        text: "Instant access to your menu ",
        className: "ml-5",
      },
      {
        image: (
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="testimonial-3"
            src="../images/QRMenu/testimonial-3.png"
          />
        ),
        text: "Grow your reach and exposure",
      },
    ],
    right_bg: testimonialsRightBg,
    right_testimonial: testimonialsState.dors_craft_beer_and_kitchen,
  }

  const banner2Props = {
    title: `DOWNLOAD FOR FREE ON`,
    buttons: <div className="d-flex justify-content-start mt-5" style={{ gap: '8px' }}>
      <a className="rounded-xl" href="https://play.google.com/store/apps/details?id=com.qrmenumaker" style={{ backgroundColor: '#FF8A00' }} target="_blank" rel="nofollow">
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="get-on-google-play"
          src="../images/QRMenu/get-on-google-play-white.png" />
      </a>
      <a className="rounded-xl" href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790" style={{ backgroundColor: '#FF8A00' }} target="_blank" rel="nofollow">
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="download-on-the-app-store"
          src="../images/QRMenu/download-on-the-app-store-white.png" />
      </a>
    </div>,
    bgColor: "#FF6B00",
    rightImage: <StaticImage
      placeholder="none"
      loading="eager"
      alt="qr-menu"
      width={450}
      src="../images/QRMenu/qr-menu.png" />
  }

  const questions = [
    {
      question: `How much does QR Menu Maker cost?`,
      answer: `Orders.co's QR menu maker is 100% free for all restaurants & businesses.`,
    },
    {
      question: `What devices are compatible with QR Menu Maker?`,
      answer: `QR Menu Maker is compatible with iOS  and Android devices, as well as web browsers on desktops and laptops.`,
    },
    {
      question: `How do I create my menu with QR Menu Maker?`,
      answer: `An in-app menu management feature allows users to create schedules, categories, items,
        and upload photos from one place.`,
    },
    {
      question: `Where do I find my QR Code?`,
      answer: `When viewing your menu in-app, click the QR code icon in the top right corner to
        display your menu's QR code.`,
    },
    {
      question: `How to I share my QR code with customers?`,
      answer: `A share button is located the QR code display screen.
        Copy a website link or download a QR image.`,
    },
  ];

  return (
    <>
      <Seo
        title={"QR Menu"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        {/* <Top {...topProps} /> */}
        <QrMenuMaker2 useH1={true}/>
        <Features
          showNumbers={true}
          smallTitle={true}
          items={features}
          title=""
        />
        <div
          className="pb-1 mb-md-4 pb-md-4 mb-xl-5 pb-xl-5 d-none"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <Title subtitle={"Easily share your QR code with customers in-store and online."}>
            <b
              className="mt-5 mt-md-0 d-block"
              style={{ fontSize: "64px", lineHeight: "64px", fontWeight: '700', opacity: 1 }}
            >
              Build your digital menu
            </b>
          </Title>
          <YoutubeVideo
            videoId={"8iuMePnkOkQ"}
            image={<StaticImage
              placeholder="none"
              loading="eager"
              alt="Suzy Antonyan"
              className="mw-100 w-100 mh-100"
              src="../images/CustomerStoriesKabob/Suzy-Antonyan.jpg"
            />}
          ></YoutubeVideo>
        </div>
        <div className="mt-5 pt-xl-5">
          <ImageTextSlider items={imageTextSliderItems} />
        </div>
        <FeaturesAlt
          showNumbers={false}
          smallTitle={true}
          items={features2}
          className="mb-5"
          title=""
        />
        <QrMenuMaker2 useH1={false}/>
        {/* <BannerAlt {...banner1Props} /> */}
        <TestimonialsAlt {...testimonialsProps} />
        <News items={data.allWpPost} />
        <Faq items={questions} />
        {/* <BannerAlt {...banner2Props} /> */}
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "qr-menu" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "qr-menu"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default QRMenuPage;
